import React from "react";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Login from "./components/Login";
import Inventario from "./components/Inventario";
import Profile from "./components/Profile";
import Sidebar from "./components/Sidebar";
import ProtectedRoute from "./components/ProtectedRoute"; // Importa el componente de ruta protegida

const App: React.FC = () => {
  const location = useLocation(); // Hook para obtener la ubicación actual

  // Verifica si estamos en el login para no mostrar el Sidebar
  const isLoginPage = location.pathname === "/login";
  return (
    <div className="d-flex">
    {/* Sidebar solo se muestra si no estamos en la página de login */}
    {!isLoginPage && <Sidebar onSelect={(menu) => console.log(menu)} />}

    <div className="flex-grow-1">
      <nav className="navbar navbar-expand navbar-dark bg-dark">
        <Link to={"/"} className="navbar-brand">
          REPORTES MAYSOL
        </Link>
        <div className="navbar-nav mr-auto">
          <li className="nav-item">
            <Link to={"https://sistema.granjasmaysol.com/#/"} className="nav-link">
              Sistema Maysol
            </Link>
          </li>
        </div>
      </nav>

      <div className="container mt-3">
        <Routes>
          {/* Protege la ruta de Home */}
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Inventario />
              </ProtectedRoute>
            }
          />
          <Route 
            path="/profile" 
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            } 
            />
          <Route path="/login" element={<Login />} />
          
        </Routes>
      </div>
    </div>
    </div>
  );
};

export default App;
