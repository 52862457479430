import React from "react";
import { Navigate } from "react-router-dom";

// Simulación de verificación de autenticación. Puedes ajustarla según tu lógica.
const isAuthenticated = () => {
  // Aquí podrías verificar en `localStorage` o en el contexto de autenticación.
  return Boolean(localStorage.getItem("user"));
};

interface ProtectedRouteProps {
  children: React.ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  if (!isAuthenticated()) {
    // Redirige a "/login" si el usuario no está autenticado.
    return <Navigate to="/login" />;
  }

  // Muestra el componente hijo si está autenticado.
  return children;
};

export default ProtectedRoute;
