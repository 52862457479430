import React, { useEffect, useState } from "react";
import { getCurrentUser, logout } from "../services/auth.service";
import { useNavigate } from "react-router-dom";

const Profile: React.FC = () => {
  const [currentUser, setCurrentUser] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const user = getCurrentUser();
    setCurrentUser(user);
  }, []);

  const handleLogout = () => {
    logout();
    navigate("/login"); // Redirige a la página de login
  };

  if (!currentUser) return <p>Debe iniciar sesion</p>;

  return (
    <div className="container">
      <header className="jumbotron">
        <h3>
          <strong>{currentUser.username}</strong> Profile
        </h3>
      </header>
      <p>
        <strong>Token:</strong> {currentUser.token.substring(0, 20)} ...{" "}
        {currentUser.token.substr(currentUser.token.length - 20)}
      </p>
      <p>
        <strong>Id:</strong> {currentUser.id}
      </p>
      <p>
        <strong>Email:</strong> {currentUser.email}
      </p>
      <p>
        <strong>Nombre Completo:</strong> {currentUser.nombreCompleto}
      </p>
      <p>
        <strong>Proyecto:</strong> {currentUser.proyecto}
      </p>
      <p>
        <strong>Cargo:</strong> {currentUser.cargo}
      </p>
      <p>
        <strong>Accesos:</strong>
      </p>
      <ul>
        {Object.entries(currentUser.accesos || {}).map(([key, value]) => (
          <li key={key}>
            {key}: {value ? "Sí" : "No"}
          </li>
        ))}
      </ul>
      <button onClick={handleLogout}>Logout</button> {/* Botón de Logout */}
    </div>
  );
};

export default Profile;
