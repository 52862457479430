import React from "react";

interface SidebarProps {
  onSelect: (menu: string) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ onSelect }) => {
  return (
    <div className="bg-dark text-white p-3 vh-100">
      <h2>Menú</h2>
      <ul className="nav flex-column">
        <li className="nav-item">
          <button className="nav-link text-white" onClick={() => onSelect("inventario")}>
            Inventario
          </button>
        </li>
        <li className="nav-item">
          <button className="nav-link text-white" onClick={() => onSelect("ventas")}>
            Ventas
          </button>
        </li>
        <li className="nav-item">
          <button className="nav-link text-white" onClick={() => onSelect("compras")}>
            Compras
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
