import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const register = (username: string, password: string) => {
  return axios.post(API_URL + "signup", {
    username,    
    password,
  });
};

export const login = async (username: string, password: string) => {
  return axios.post(`${API_URL}/api/users/token/`,{
      username,
      password
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

export const logout = () => {
  localStorage.removeItem("user");
};

export const getCurrentUser = () => {
  const userStr = localStorage.getItem("user");
  if (userStr) {
    const user = JSON.parse(userStr);
    console.log("User data from localStorage:", user); // <-- Agrega esta línea
    return {
      token: user.token,
      ...user.me
    };
  }
  return null;
};
export const getToken = (): string | null => {
  const userStr = localStorage.getItem("user");
  if (userStr) {
    const user = JSON.parse(userStr);
    return user.token; // Asegúrate de que el token esté en el campo correcto
  }
  return null;
};


