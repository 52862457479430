import React, { useState, useEffect } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import { getToken } from "../services/auth.service";

interface Bodega {
  id: number;
  nombre: string;
}

interface InventarioItem {
  id: number;
  lote: {
    id: number;
    lote: string;
  };
  producto: string;
  cantidad: number;
  costo_unitario: number;
  total_costo_unitario: number;
}

interface InventarioResponse {
  count: number; // Total de resultados
  results: InventarioItem[];
  next: string | null;
  previous: string | null;
}

const Inventario: React.FC = () => {
  const [bodegas, setBodegas] = useState<Bodega[]>([]);
  const [selectedBodega, setSelectedBodega] = useState<number | null>(null);
  const [inventario, setInventario] = useState<InventarioItem[]>([]);
  const [nextPageUrl, setNextPageUrl] = useState<string | null>(null);
  const [prevPageUrl, setPrevPageUrl] = useState<string | null>(null);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const API_URL = process.env.REACT_APP_API_URL;
  const token = getToken();

  // Obtener la lista de bodegas
  useEffect(() => {
    if (token) {
      axios
        .get(`${API_URL}/api/bodegas/?auth_token=${token}`)
        .then((response) => setBodegas(response.data.results))
        .catch((error) => console.error("Error al cargar bodegas:", error));
    } else {
      console.error("Token no encontrado");
    }
  }, [API_URL, token]);

  // Función para obtener el inventario de una bodega específica en la página actual
  const fetchInventario = async (url: string | null) => {
    if (!url) return;

    const params = {
      auth_token: token,
      bodega: selectedBodega,
      cant_zero: "true",
      ordering: "stock__producto__nombre",
    };

    try {
      const normalizedUrl = url ? url.replace("http://localhost:8000", API_URL || "") : "";
      const response = await axios.get<InventarioResponse>(normalizedUrl, { params });

      // Actualizar los datos del inventario
      setInventario(response.data.results);
      setNextPageUrl(response.data.next ? response.data.next.replace("http://localhost:8000", API_URL || "") : null);
      setPrevPageUrl(response.data.previous ? response.data.previous.replace("http://localhost:8000", API_URL || "") : null);


      // Calcular total de páginas y actualizar página actual
      const pageSize = 10; // Tamaño de la paginación
      setTotalPages(Math.ceil(response.data.count / pageSize));

      // Determinar página actual según URL
      const urlParams = new URLSearchParams(new URL(normalizedUrl).search);
      const page = parseInt(urlParams.get("page") || "1", 10);
      setCurrentPage(page);
    } catch (error) {
      console.error("Error al cargar inventario:", error);
    }
  };

  // Obtener el inventario cuando cambia la bodega seleccionada
  useEffect(() => {
    if (selectedBodega && token) {
      fetchInventario(`${API_URL}/api/inventario/`);
    }
  }, [selectedBodega, API_URL, token]);
  // Función para obtener todos los datos paginados
  const fetchAllInventario = async (): Promise<InventarioItem[]> => {
    let allData: InventarioItem[] = [];
    let nextPageUrl: string | null = `${API_URL}/api/inventario/?auth_token=${token}&bodega=${selectedBodega}&cant_zero=true&ordering=stock__producto__nombre`;

    try {
      while (nextPageUrl) {
        //@ts-ignore
        const normalizedUrl = nextPageUrl.replace("http://localhost:8000", API_URL || "");        
        //@ts-ignore
        const response = await axios.get<InventarioResponse>(normalizedUrl);
        allData = allData.concat(response.data.results);
        nextPageUrl = response.data.next ? response.data.next.replace("http://localhost:8000", API_URL || "") : null;
      }
    } catch (error) {
      console.error("Error al cargar inventario paginado:", error);
    }

    return allData;
  };

  // Función para exportar todos los datos a Excel
  const handleExportAll = async () => {
    try {
      const allInventario = await fetchAllInventario();

      if (allInventario.length === 0) {
        console.error("No hay datos para exportar.");
        return;
      }

      const data = allInventario.map((item) => ({
        "ID Lote": item.lote.id,
        "Fecha Lote": item.lote.lote,
        Producto: item.producto,
        Cantidad: item.cantidad,
        "Costo Unitario": item.costo_unitario,
        "Total Costo Unitario": item.total_costo_unitario,
      }));

      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Inventario");

      XLSX.writeFile(workbook, "inventario_completo.xlsx");
    } catch (error) {
      console.error("Error al exportar inventario:", error);
    }
  };

  return (
    <div className="container mt-4">
      <h3>Inventario</h3>
      <div className="mb-3">
        <label htmlFor="bodegaSelect" className="form-label">
          Selecciona una bodega:
        </label>
        <select
          id="bodegaSelect"
          className="form-select"
          value={selectedBodega || ""}
          onChange={(e) => setSelectedBodega(Number(e.target.value))}
        >
          <option value="">-- Selecciona una bodega --</option>
          {bodegas.map((bodega) => (
            <option key={bodega.id} value={bodega.id}>
              {bodega.nombre}
            </option>
          ))}
        </select>
      </div>

      {selectedBodega && (
        <div>
          <h4>Inventario de la Bodega Seleccionada</h4>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>ID Lote</th>
                <th>Fecha Lote</th>
                <th>Producto</th>
                <th>Cantidad</th>
                <th>Costo Unitario</th>
                <th>Total Costo Unitario</th>
              </tr>
            </thead>
            <tbody>
              {inventario.map((item) => (
                <tr key={item.id}>
                  <td>{item.lote.id}</td>
                  <td>{item.lote.lote}</td>
                  <td>{item.producto}</td>
                  <td>{item.cantidad}</td>
                  <td>{item.costo_unitario}</td>
                  <td>{item.total_costo_unitario}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Indicador de página actual y total */}
          <div className="d-flex justify-content-between align-items-center">
            <span>
              Página {currentPage} de {totalPages}
            </span>

            {/* Controles de Paginación */}
            <div className="pagination-controls">
              <button
                disabled={!prevPageUrl}
                onClick={() => fetchInventario(prevPageUrl)}
                className="btn btn-secondary me-2"
              >
                Anterior
              </button>
              <button
                disabled={!nextPageUrl}
                onClick={() => fetchInventario(nextPageUrl)}
                className="btn btn-secondary"
              >
                Siguiente
              </button>
            </div>
          </div>

          {/* Botón para exportar todos los datos */}
          <button
            onClick={handleExportAll}
            className="btn btn-primary mt-3"
            disabled={!selectedBodega}
          >
            Descargar Inventario Completo
          </button>
        </div>
      )}
    </div>
  );
};

export default Inventario;
